//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import nieuwsberichtenmetcontent from '~/graphql/queries/nieuwsberichtenmetcontent.gql';
import activiteitCategories from '~/graphql/queries/activiteitCategories.gql';
import page from '~/plugins/mixins/page';

export default {
  auth: false,
  mixins: [page],
  apollo: {
    activiteitCategories: {
      query: activiteitCategories,
    },
    nieuwsBerichten: {
      query: nieuwsberichtenmetcontent,
      variables() {
        return {
          limit: 5,
        };
      },
    },
  },
  data: () => ({
    nieuwsBerichten: {
      data: [],
    },
    selectedFilter: 'any',
    activiteitCategories: [],
    headerImg: require('~/assets/img/3-handen-samen.jpg'),
    overHetNvpaImg: require('~/assets/img/bruggenbouwer.jpg'),
    uitgangspuntenImg1: require('~/assets/img/uitgangspunten-1.jpg'),
    uitgangspuntenImg2: require('~/assets/img/uitgangspunten-2.jpg'),
    showPopup: false,
  }),
  computed: {
    options() {
      const options = {any: 'Alles weergeven'};
      for (let i = 0; i < this.activiteitCategories.length; ++i) {
        options[this.activiteitCategories[i].slug] = this.activiteitCategories[i].name;
      }
      return options;
    },
    leftSlides() {
      const slides = this.page.template.slides || [];

      return slides.slice(0, Math.ceil(slides.length / 2));
    },
    rightSlides() {
      const slides = this.page.template.slides || [];

      return slides.slice(Math.floor(slides.length / 2), slides.length);
    },
  },
  mounted() {
    if (this.$route.query.p) {
      this.showPopup = true;
      this.$router.push(this.$route.path);
    }
  },
  methods: {
    onSearch(data) {
      this.$router.push({name: 'therapeut-vinden', query: data});
    },
  },
};
