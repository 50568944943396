//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    voorClientenImg: require('~/assets/img/voor-clienten.jpg'),
    voorTherapeutenImg: require('~/assets/img/voor-therapeuten.jpg'),
    voorClientenButtons: [
      {name: 'FAQ (veelgestelde vragen)', link: '/faq'},
      {name: 'Over het NVPA', link: '/over-nvpa/wie-zijn-wij'},
      {name: 'Zorgverzekeraars', link: '/zorgverzekering'},
      {name: 'Cliënttevredenheidsonderzoek', link: '/clienttevredenheidsonderzoek'},
      {name: 'Klacht indienen', link: '/klacht-indienen'},
    ],
    voorTherapeutenButtons: [
      {name: 'FAQ (veelgestelde vragen)', link: '/faq'},
      {name: 'Waarom lid worden van het NVPA', link: '/waarom-lid-worden'},
      {name: 'Aanmelden als lid', link: '/lid-worden'},
      {name: 'Opgeven voor activiteiten', link: '/activiteiten-trainingen'},
      {name: 'Vakgroepen', link: '/vakgroepen'},
      {name: 'Reglementen', link: '/reglementen'},
      {name: 'Nieuws', link: '/over-nvpa/nieuws'},
    ],
  }),
};
