//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  auth: false,
  props: {
    activiteiten: {
      type: Array,
      required: true,
    },
    filter: {
      type: String,
      required: false,
      default: () => 'any',
    },
  },
  computed: {
    filteredActiviteiten() {
      if (this.filter === 'any') {
        return this.activiteiten;
      }
      return this.activiteiten.filter(a => a.categories.some(c => c.slug === this.filter));
    },
  },
};
