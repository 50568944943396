//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import activiteiten from '~/graphql/queries/activiteiten.gql';

export default {
  apollo: {
    activiteiten: {
      query: activiteiten,
      result() {
        this.calculateYears();
      },
    },
  },
  props: {
    filter: {
      type: String,
      required: false,
      default: () => 'any',
    },
  },
  data: () => ({
    activiteiten: [],
    currentYear: new Date().getFullYear(),
    years: [],
    selectedJaar: null,
  }),
  computed: {
    yearActivities() {
      return this.activiteiten.filter((activity) => {
        return this.$moment(activity.datum, 'YYYY-MM-DD').year() === this.selectedJaar;
      });
    },
  },
  mounted() {
    this.calculateYears();
  },
  methods: {
    calculateYears() {
      // Only in December
      if (new Date().getMonth() === 11) {
        const hasActivities = this.activiteiten.filter((activity) => {
          return this.$moment(activity.datum, 'YYYY-MM-DD').year() === this.currentYear;
        }).length;

        if (!hasActivities) {
          this.years = [new Date().getFullYear() + 1];
        } else {
          this.years = [new Date().getFullYear(), new Date().getFullYear() + 1];
        }
      }
      this.selectedJaar = this.years[0] || this.currentYear;
    },
  },
};
