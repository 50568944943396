//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    date: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
      required: true,
    },
    hoverAnimation: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    momentDate() {
      return this.$moment(this.date, 'YYYY-MM-DD');
    },
  },
};
