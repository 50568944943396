//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import hoofdrichtingen from '~/graphql/queries/hoofdrichtingen.gql';
import zoektermen from '~/graphql/queries/zoektermen.gql';

export default {
  apollo: {
    zoektermen: {
      query: zoektermen,
      variables() {
        return {
          limit: 5,
          query: this.formData.query,
        };
      },
    },
    hoofdrichtingen: {
      query: hoofdrichtingen,
    },
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        distance: 0,
        city: '',
        query: '',
        name: '',
        specialisatie: 0,
        hoofdrichting: 0,
      }),
    },
  },
  data: ({value}) => ({
    formData: {
      distance: value.distance,
      city: value.city,
      query: value.query,
      name: value.name,
      specialisatie: value.specialisatie,
      hoofdrichting: value.hoofdrichting,
    },
    openSearchOptions: false,
    hoofdrichtingen: [],
    zoektermen: [],
  }),
  computed: {
    distanceValues() {
      const distances = {0: 'Afstand (km)'};
      for (let i = 5; i <= 25; i += 5) {
        distances[i] = `< ${i}km`;
      }
      return distances;
    },
    hoofdrichtingValues() {
      const hoofdrichtingen = {0: 'Kies een specialisatie'};
      this.hoofdrichtingen.forEach(h => hoofdrichtingen[h.id] = h.title);
      return hoofdrichtingen;
    },
    zoektermenComputed() {
      if (this.zoektermen?.length) {
        return this.zoektermen.map((m) => { return {value: m.title, label: m.title}; });
      }
      return [];
    },
  },
  watch: {
    value(val) {
      if (Object.keys(this.formData).some(key => this.formData[key] !== val[key])) {
        this.formData = val;
      }
    },
  },
  methods: {
    onSearch(data) {
      this.$emit('input', data);
    },
    close() {
      this.openSearchOptions = false;
    },
    setTerm(term) {
      this.formData.query = term;
      this.$refs.submit.click();
    },
  },
};
