import { render, staticRenderFns } from "./PaymentPopup.vue?vue&type=template&id=4e4fa334&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GeneralModal: require('/data/www/nvpa.org/nvpa/nuxt/components/general/Modal.vue').default})
